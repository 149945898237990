export const SidebarContent = [
  { path: "/admin/dashboard", label: "Overview" },
  { path: "/admin/jobListing", label: "Job Listing" },
  { path: "/admin/talents", label: "Talents" },
  { path: "/admin/admins", label: "Admins" },
  { path: "/admin/super-admins", label: "Super Admin" },
  { path: "/admin/recruiters", label: "Recruiters" },
  { path: "/admin/feedback", label: "FeedBack" },
  { path: "/admin/reports", label: "Report" },
  { path: "/admin/conversations", label: "Conversations" },
  { path: "/admin/updates", label: "Updates" },
];
