import React from 'react'

const Loading = () => {
    return (
        <td colSpan='6' className="text-center" style={{ padding: '0 25px' }}>
            <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </td>
    )
}

export default Loading
