// import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./css/InputField.css";
import { InputAdornment } from "@mui/material";
import { CopyAllTwoTone } from "@mui/icons-material";

const InputField = ({
  name,
  value,
  type,
  onChange,
  onFocus,
  placeholder,
  title,
  helperText,
  isIconPresent,
  isReadOnly = false,
  icon: IconComponent = CopyAllTwoTone,
  iconPosition,
  variant = "standard",
  className
}) => {
  return (
    <>
      <label className="input_label">{title}</label>
      <TextField
        name={name}
        onFocus={onFocus}
        id="standard-basic"
        variant={variant}
        value={value}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        helperText={helperText}
        className={className}
        InputProps={{
          endAdornment: isIconPresent && (
            <InputAdornment
              position={iconPosition}
              sx={{
                cursor: "pointer",
              }}
            >
              <IconComponent />
            </InputAdornment>
          ),
          readOnly: isReadOnly,
        }}
      />
    </>
  );
};

export default InputField;
