import { useNavigate } from 'react-router-dom';
import classNames from '../../utils/conditional-css-classes/classNames';
import s from './css/Button.module.sass';

function Button(props) {
	const { text, small, onClick, fadeFill, darkFill, redirect, disabled, emptyFill, secondary, greenBtn ,redBtn, fadeNull, primaryNull, secondaryNull, primaryEmptyFill, buttonType, icon, ...rest } = props;

	const { modal: md, doDismissModal: dms, modalTarget: target } = rest;

	const navigate = useNavigate();

	function callback() {
		if (redirect) {
			navigate({
				pathname: redirect.split('?')[0],
				search: redirect.split('?')[1] || undefined,
			});
		}
		if (onClick) onClick();
	}

	return (
		<div className={s.main}>
			<button
				type={buttonType || 'button'}
				className={classNames(
					s.button,
					small && s.smallBtn,
					secondary && s.secondaryBtn,
					darkFill && s.darkBtn,
					fadeFill && s.fadeBtn,
					emptyFill && s.emptyBtn,
					primaryEmptyFill && s.primaryEmptyBtn,
					greenBtn && s.greenBtn,
					redBtn && s.redBtn,
					secondaryNull && s.secondaryOutlineBtn,
					primaryNull && s.primaryOutlineBtn,
					fadeNull && s['fade-btn-null'],
					disabled && s.disabled
				)}
				onClick={() => callback()}
				data-bs-toggle={md && `modal`}
				data-bs-target={md && `#${target}`}
				data-bs-dismiss={dms && `modal`}
				disabled={disabled}>
					{icon && <span className={s.icon}>{icon}</span>}
				{text || `button component`}
			</button>
		</div>
	);
}

export default Button;
