import React from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import "./css/Navbar.style.sass";
import { SidebarContent } from "./SideBarContent";

const Navbar = ({
  logo,
  centerContent,
  rightContent,

  bgColor,
}) => {
  const navbar = useRef();
  const sidebar = useRef();

  const fadeNavbar = () => {
    if (window.pageYOffset > 100) {
      navbar.current?.classList.add("fade");
    } else navbar.current?.classList.remove("fade");
  };

  window.addEventListener("scroll", fadeNavbar);
  const slideIn = () => {
    sidebar.current?.classList.add("active");
    sidebar.current?.classList.toggle("slide-out");
  };
 

  return (
    <div id="Navbar_Main_Container">
      <div
        className="con-navbar"
        style={{ background: bgColor || `#FFFBF2` }}
        ref={navbar}
      >
        <div className="container-01">
          <div className="navbar">
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
              <Link>
                <div className="con-left">
                  <img
                    id="logo"
                    src={"/img/svg/frontters.svg" || logo}
                    alt="Talents"
                    className="w-50"
                  />
                </div>
              </Link>
              <div className="con-center d-none d-xl-block">
                <div className="center">{centerContent || ``}</div>
              </div>
              <div className="con-right d-none d-lg-block">
                {rightContent || ``}
              </div>
              <div className="con-menu d-lg-none" style={{marginLeft:'auto'}} onClick={slideIn}>
                <div className="menu">
                  <i className="fa-solid fa-bars"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="con-sidebar d-xl-none">
        <div className="sidebar" ref={sidebar}>
         
          <ul id="con-navbar-sidebar">

            {SidebarContent.map((items)=>(
               <Link to={items.path} className="active">
               <li>{items.label}</li>
             </Link>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
